html {
  font-size: 10px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #333333;
}

body {
  margin: 16px;
}
